import React, { useEffect, useState, useContext } from 'react'
import { useFormContext } from "react-hook-form"
import { Grid } from '@material-ui/core'
import DataRestoreApiUrls from 'src/apiUrls/DataRestoreApiUrls.js'
import Namespace from 'src/constants/locale/Namespace'
import DataRestoreKeys from '../../constants/locale/key/DataRestore'
import makeValidationSchema from './MakeValidationSchema'
import CngBackdrop from 'src/components/cngcomponents/CngBackDrop'
import DataRestoreButtonComponent from './component/DataRestoreButtonComponent'
import CngSection from '../../components/cngcomponents/CngSection'
import { v4 as uuid } from 'uuid'

import {
  components,
  DataFlattener,
  useServices,
  useTranslation,
  constants
} from 'cng-web-lib'

const {
  form: { field: { 
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField 
    } 
  },
  table: { useFetchCodeMaintenanceLookup },
  CngGridItem
} = components

const { filter: { EQUAL } } = constants

const FILE_FOR_USER_PARTY_NAME = 'fileForUserPartyName'
const FILE_FOR_USER_BY_ADMIN = 'fileForUserByAdmin'
const FILE_FOR_USER_DETAILS = 'fileForUserDetails'

const DEFAULT_INITIAL_VALUES = Object.freeze({ 
  module: '',
  type: '',
  restoreBy: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { setValue, getValues, reset, watch, trigger } = useFormContext();
  const { securedSendRequest, fetchRecords } = useServices();
  const { translate } = useTranslation(Namespace.DATA_RESTORE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [subModuleDetailState, setSubModuleDetailState] = useState(false);
  const [subModuleState, setSubModuleState] = useState(uuid());
  const [restoreByDetailState, setRestoreByDetailState] = useState(false);
  const [restoreByState, setRestoreByState] = useState(uuid());
  const [restoreBySearchCriteriaState, setRestoreBySearchCriteriaState] = useState(false);
  const [restoreByDateRangeState, setRestoreByDateRangeState] = useState(false);

  const [configState, setConfigState] = useState(
    {
      labelModule: translatedTextsObject.module + ' *',
      labelType: translatedTextsObject.type + ' *',
      labelRestoreBy: translatedTextsObject.restoreBy + ' *',
      labelSearchCriteria: '',
      labelSearchCriteria2: '',
      labelSearchCriteria3: '',
      labelSearchCriteria4: '',
      labelSearchCriteria5: '',
      dropDownSubModuleType: '-',
      dropDownRestoreBy: '-',
      dataRestoreAPIUrl: ''
    }
  )

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [loadingState, setLoadingState] = new useState(false);
  const [isSuperParty, setSuperParty] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({})  
  const [user, setUser] = useState({})  

  useEffect(() => {
    let module = getValues('module');
    setValue('type', '')

    //Add on changes
    if (module == 'ACEHW') {
      configState.dropDownSubModuleType = 'DR_ACEHW_SUB_MODULE'
    } else if (module == 'EHBL') {
      configState.dropDownSubModuleType = 'DR_EHBL_SUB_MODULE'
    } else if (module == 'ACEOCN') {
      configState.dropDownSubModuleType = 'DR_ACEOCN_SUB_MODULE'
    }

    //refresh form details if module is not empty
    if (module != null && module != undefined && module != '') {
      setSubModuleState(uuid())
      setSubModuleDetailState(true);
    }
  }, [getValues('module')]);

  useEffect(() => {
    let module = getValues('module');
    let type = getValues('type');
    if (type != '' && type != undefined) {
      setRestoreByDetailState(true)
      setValue('restoreBy', '')

      //Add on changes
      if (module == 'ACEHW') {
        configState.dataRestoreAPIUrl = DataRestoreApiUrls.ACE_HIGHWAY_DR
      }
      else if (module == 'EHBL' && type != 'D4_NOTICE') {
        configState.dataRestoreAPIUrl = DataRestoreApiUrls.EHBL_DR
      }
      else if (module == 'EHBL' && type == 'D4_NOTICE') {
        configState.dataRestoreAPIUrl = DataRestoreApiUrls.SHARED_DR
      }
      else if (module == 'ACEOCN') {
        configState.dataRestoreAPIUrl = DataRestoreApiUrls.ACE_OCEAN_DR
      }      
    }
  }, [getValues('type')]);

  useEffect(() => {
    let restoreBy = getValues('restoreBy');

    if (restoreBy == 'BOND_NUMBER') {
      configState.labelSearchCriteria = translatedTextsObject.bondNumber
      setRestoreBySearchCriteriaState(true)
      setRestoreByDateRangeState(false)
    }

    if (restoreBy == 'TRIP_NUMBER') {
      configState.labelSearchCriteria = translatedTextsObject.tripNumber
      setRestoreBySearchCriteriaState(true)
      setRestoreByDateRangeState(false)
    }

    if (restoreBy == 'SHIPMENT_CONTROL_NUMBER') {
      configState.labelSearchCriteria = translatedTextsObject.shipmentControlNumber
      setRestoreBySearchCriteriaState(true)
      setRestoreByDateRangeState(false)
    }

    if (restoreBy == 'PRIMARY_CCN') {
      configState.labelSearchCriteria = translatedTextsObject.primaryCCN
      setRestoreBySearchCriteriaState(true)
      setRestoreByDateRangeState(false)
    }

    if (restoreBy == 'PREVIOUS_CCN') {
      configState.labelSearchCriteria = translatedTextsObject.previousCCN
      setRestoreBySearchCriteriaState(true)
      setRestoreByDateRangeState(false)
    }

    if (restoreBy == 'CCN') {
      configState.labelSearchCriteria = translatedTextsObject.ccn
      setRestoreBySearchCriteriaState(true)
      setRestoreByDateRangeState(false)
    }

    if (restoreBy == 'CBSA_REF_NO') {
      configState.labelSearchCriteria = translatedTextsObject.cbsaRefNo
      setRestoreBySearchCriteriaState(true)
      setRestoreByDateRangeState(false)
    }

    if (restoreBy == 'DATE_RANGE') {
      setRestoreBySearchCriteriaState(false)
      setRestoreByDateRangeState(true)
    }

    if (restoreBy == 'MANIFEST_KEY') {
      configState.labelSearchCriteria = translatedTextsObject.scac
      configState.labelSearchCriteria2 = translatedTextsObject.vesselName
      configState.labelSearchCriteria3 = translatedTextsObject.voyageNumber
      configState.labelSearchCriteria4 = translatedTextsObject.portOfDischarge
      configState.labelSearchCriteria5 = translatedTextsObject.eta
      setRestoreBySearchCriteriaState(true)
      setRestoreByDateRangeState(false)
    }    
  }, [getValues('restoreBy')]);

  function getUserId() {
    trigger();
    let username = getValues('userId');

    function onSuccess(response) {
      let data = response.data;

      if (data) {
        let error = data.errorMessages;
        if (error === null || error === undefined) {
          if (data.content && data.content.length > 0) {
            getPartyId(data.content[0].id);
          } else {
            showNotification('error', 'Invalid user ID.');
          }
        } else {
          showNotification('error', 'Invalid user ID.');
        }
      }
    }

    function onError(error) {
      showNotification('error', "We've encountered some issue.Please try again later.");
    }

    function onComplete() { }

    const formData = {
      baseFilterDTO: {
        filterType: 'AND',
        filterProperties: [{
          fieldName: 'loginId',
          operatorType: 'EQUAL',
          value1: username
        }],
        sortProperties: []
      }
    }

    const config = {}

    if (username) {
      securedSendRequest.execute('POST', DataRestoreApiUrls.GET_TPR_USER, formData, onSuccess, onError, onComplete, config, null);
    }
  }

  function getPartyId(userProfileId) {

    function onSuccess(response) {
      let data = response.data;

      if (data) {
        let error = data.errorMessages;
        if (error === null || error === undefined) {
          if (data.content && data.content.length > 0) {
            let tprUserDetails = data.content[0].tprUserDetail;

            if (tprUserDetails != null & tprUserDetails != undefined) {
              setValue('partyId', tprUserDetails.partyId);
              onRestore()
            }

          } else {
            showNotification('error', 'Invalid user ID.');
          }
        } else {
          showNotification('error', 'Invalid user ID.');
        }
      }
    }

    function onError(error) {
      showNotification('error', "We've encountered some issue.Please try again later.");
    }

    function onComplete() { }

    const formData = {
      baseFilterDTO: {
        filterType: 'AND',
        filterProperties: [{
          fieldName: 'userProfileId',
          operatorType: 'EQUAL',
          value1: userProfileId
        }],
        sortProperties: []
      }
    }

    const config = {}

    securedSendRequest.execute('POST', DataRestoreApiUrls.GET_TPR_USER_PREFERENCE, formData, onSuccess, onError, onComplete, config, null);
  }  

  function onRestore() {
    let submitData = {}

    submitData.module = getValues('module')
    submitData.type = getValues('type')
    submitData.restoreBy = getValues('restoreBy')
    submitData.searchCriteria = getValues('searchCriteria')
    submitData.searchCriteria2 = getValues('searchCriteria2')
    submitData.searchCriteria3 = getValues('searchCriteria3')
    submitData.searchCriteria4 = getValues('searchCriteria4')
    submitData.searchCriteria5 = getValues('searchCriteria5')
    submitData.dateFrom = getValues('dateFrom')
    submitData.dateTo = getValues('dateTo')
    submitData.dayToRetain = getValues('dayToRetain')
    submitData.partyId = getValues('partyId')

    function onSuccess(response) {

      if (response.status == 200) {
        const data = response.data
        if (data.errorMessages != null && data.errorMessages != undefined && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)
        } else {
          showNotification('success', translatedTextsObject.successMessage)
        }
      } else {
        showNotification('error', translatedTextsObject.errorMessage)
      }
    }

    function onError(error) {
      console.error('Error', error)
      showNotification('error', translatedTextsObject.errorMessage)
    }

    function onComplete(response) {
      setLoadingState(false)
    }
    const config = {}

    //validate form
    const result = trigger()
    result.then(function (result) {
      if (result == true) {
        setLoadingState(true)
        //submit
        securedSendRequest.execute('POST', configState.dataRestoreAPIUrl, submitData, onSuccess, onError, onComplete, config, null);
      }
    })
  }

  function onSubmit() {
    getUserId()
  }

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.TITLE
    )

    let module = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.MODULE
    )

    let type = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.TYPE
    )

    let restoreBy = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.RESTORE_BY
    )

    let dateFrom = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.DATE_FROM
    )
    
    let dateTo = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.DATE_TO
    )
    
    let bondNumber = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.BOND_NUMBER
    )    

    let tripNumber = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.TRIP_NUMBER
    )  

    let shipmentControlNumber = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.SHIPMENT_CONTROL_NUMBER
    )  

    let dayToRetain = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.DAY_TO_RETAIN
    )  

    let userId = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.USER_ID
    )

    let errorMessage = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.ERROR_MESSAGE
    )

    let successMessage = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.SUCCESS_MESSAGE
    )
   
    let primaryCCN = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.PRIMARY_CCN
    )

    let previousCCN = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.PREVIOUS_CCN
    )

    let ccn = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.CCN
    )

    let cbsaRefNo = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.CBSA_REF_NO
    )

    let scac = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.SCAC
    )

    let vesselName = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.VESSEL_NAME
    )

    let voyageNumber = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.VOYAGE_NUMBER
    )

    let portOfDischarge = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.PORT_OF_DISCHARGE
    )

    let eta = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.ETA
    )

    return {
      title,
      module,
      type,
      restoreBy,
      userId,
      dateFrom,
      dateTo,
      bondNumber,
      tripNumber,
      shipmentControlNumber,
      dayToRetain,
      errorMessage,
      successMessage,
      primaryCCN,
      previousCCN,
      ccn,
      cbsaRefNo,
      scac,
      vesselName,
      voyageNumber,
      portOfDischarge,
      eta
    }
  }

  return loadingState ? (
    <CngBackdrop loading />
  ) : (
    <CngSection title={translatedTextsObject.title}>
      <Grid container justify='flex-end' spacing={2}>
        <CngGridItem xs={12}>
          <Grid container spacing={2}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userId}>
              <CngCodeMasterAutocompleteField
                name='module'
                label={configState.labelModule}
                disabled={disabled}
                codeType='DR_MODULE'
              />
            </CngGridItem>
            {subModuleDetailState &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={subModuleState}
                  name='type'
                  label={configState.labelType}
                  disabled={disabled}
                  codeType={configState.dropDownSubModuleType}
                />
              </CngGridItem>
            }
            {restoreByDetailState && getValues('module') == 'ACEHW' && getValues('type') == 'INBOND_7512' &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={restoreByState}
                  name='restoreBy'
                  label={configState.labelRestoreBy}
                  disabled={disabled}
                  codeType='DR_ACEHWY_INBOND_7512_BY'
                />
              </CngGridItem>
            }
            {restoreByDetailState && getValues('module') == 'ACEHW' && getValues('type') == 'MANIFEST' &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={restoreByState}
                  name='restoreBy'
                  label={configState.labelRestoreBy}
                  disabled={disabled}
                  codeType='DR_ACEHWY_MANIFEST_BY'
                />
              </CngGridItem>
            }
            {restoreByDetailState && getValues('module') == 'ACEHW' && getValues('type') == 'SHIPMENT' &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={restoreByState}
                  name='restoreBy'
                  label={configState.labelRestoreBy}
                  disabled={disabled}
                  codeType='DR_ACEHWY_SHIPMENT_BY'
                />
              </CngGridItem>
            }
            {restoreByDetailState && getValues('module') == 'EHBL' && getValues('type') == 'MANIFEST' &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={restoreByState}
                  name='restoreBy'
                  label={configState.labelRestoreBy}
                  disabled={disabled}
                  codeType='DR_EHBL_MANIFEST_BY'
                />
              </CngGridItem>
            }
             {restoreByDetailState && getValues('module') == 'EHBL' && getValues('type') == 'SA_CLOSE_MESSAGE' &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={restoreByState}
                  name='restoreBy'
                  label={configState.labelRestoreBy}
                  disabled={disabled}
                  codeType='DR_EHBL_SA_CLOSE_MESSAGE_BY'
                />
              </CngGridItem>
            }
             {restoreByDetailState && getValues('module') == 'EHBL' && getValues('type') == 'MANIFEST_FORWARD' &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={restoreByState}
                  name='restoreBy'
                  label={configState.labelRestoreBy}
                  disabled={disabled}
                  codeType='DR_EHBL_MANIFEST_FORWARD_BY'
                />
              </CngGridItem>
            }
             {restoreByDetailState && getValues('module') == 'EHBL' && getValues('type') == 'D4_NOTICE' &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={restoreByState}
                  name='restoreBy'
                  label={configState.labelRestoreBy}
                  disabled={disabled}
                  codeType='DR_EHBL_D4_NOTICE_BY'
                />
              </CngGridItem>
            }
            {restoreByDetailState && getValues('module') == 'ACEOCN' && getValues('type') == 'MANIFEST' &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={restoreByState}
                  name='restoreBy'
                  label={configState.labelRestoreBy}
                  disabled={disabled}
                  codeType='DR_ACEOCN_MANIFEST_BY'
                />
              </CngGridItem>
            }

            {restoreBySearchCriteriaState && getValues('module') != 'ACEOCN' &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngTextField
                  name='searchCriteria'
                  inputProps={{ maxLength: 35 }}
                  label={configState.labelSearchCriteria}
                  disabled={disabled}
                  isRequired
                  size='small'
                />  
              </CngGridItem>
            }

            {restoreBySearchCriteriaState && getValues('module') == 'ACEOCN' && getValues('type') == 'MANIFEST' &&
              <>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngTextField
                  name='searchCriteria'
                  inputProps={{ maxLength: 35 }}
                  label={configState.labelSearchCriteria}
                  disabled={disabled}
                  isRequired
                  size='small' />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngTextField
                  name='searchCriteria2'
                  inputProps={{ maxLength: 35 }}
                  label={configState.labelSearchCriteria2}
                  disabled={disabled}
                  isRequired
                  size='small' />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngTextField
                  name='searchCriteria3'
                  inputProps={{ maxLength: 35 }}
                  label={configState.labelSearchCriteria3}
                  disabled={disabled}
                  isRequired
                  size='small' />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngTextField
                  name='searchCriteria4'
                  inputProps={{ maxLength: 35 }}
                  label={configState.labelSearchCriteria4}
                  disabled={disabled}
                  isRequired
                  size='small' />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngDateField
                  name='searchCriteria5'
                  label={configState.labelSearchCriteria5}
                  disabled={disabled}
                  isRequired
                  size='small' />
              </CngGridItem>                                          
              </>
            }            
          
            {restoreByDateRangeState &&
              <>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                  <CngDateField
                    name='dateFrom'
                    label={translatedTextsObject.dateFrom}
                    disabled={disabled}
                    isRequired
                    size='small' />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                  <CngDateField
                    name='dateTo'
                    label={translatedTextsObject.dateTo}
                    disabled={disabled}
                    isRequired
                    size='small' />
                </CngGridItem>
              </>  
            }       
            {(restoreBySearchCriteriaState || restoreByDateRangeState) &&
              <>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                  <CngTextField
                    name='dayToRetain'
                    inputProps={{ maxLength: 35 }}
                    label={translatedTextsObject.dayToRetain}
                    disabled={disabled}
                    isRequired
                    size='small'
                  />  
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                  <CngTextField
                    name='userId'
                    inputProps={{ maxLength: 35 }}
                    label={translatedTextsObject.userId}
                    disabled={disabled}
                    isRequired
                    size='small'
                  />  
                </CngGridItem>       
              </>      
            }            
          </Grid>
        </CngGridItem>
        <CngGridItem xs='auto'>
          <DataRestoreButtonComponent onSubmit={onSubmit} />
        </CngGridItem>
      </Grid>
    </CngSection>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties; 