import React from "react";
import Namespace from 'src/constants/locale/Namespace'
import DataRestoreKeys from '../../../constants/locale/key/DataRestore'
import { components, useTranslation } from 'cng-web-lib'

const {
  button: { CngButton }
} = components

function DataRestoreButtonComponent({
  onSubmit,
}) {

  const { translate } = useTranslation(Namespace.DATA_RESTORE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {

    let submitButton = translate(
      Namespace.DATA_RESTORE,
      DataRestoreKeys.SUBMIT_BUTTON
    )
    return {
      submitButton
    }
  }

  return (
    <CngButton onClick={onSubmit}>
      {translatedTextsObject.submitButton}
    </CngButton>
  );
}

export default DataRestoreButtonComponent;
