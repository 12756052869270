import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import DataRestoreKeys from '../../constants/locale/key/DataRestore'
import moment from 'moment'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    module: Yup.string().nullable().required(requiredMessage),
    type: Yup.string().nullable().required(requiredMessage),
    restoreBy: Yup.string().nullable().required(requiredMessage),
    searchCriteria: Yup.string().when('restoreBy', {
      is: (value) => value === 'BOND_NUMBER',
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string()
    }).nullable(),
    dateFrom: Yup.string().when('restoreBy', {
      is: (value) => value === 'DATE_RANGE',
      then: Yup.string().required(requiredMessage).test(
        'dateFrom',
        dateTypeErrorMessage,
        value => {
          return moment(value, 'YYYY-MM-DD').isValid()
        },
      ),
      otherwise: Yup.string()
    }).nullable(),
    dateTo: Yup.string().when('restoreBy', {
      is: (value) => value === 'DATE_RANGE',
      then: Yup.string().required(requiredMessage).test(
        'dateTo',
        dateTypeErrorMessage,
        value => {
          return moment(value, 'YYYY-MM-DD').isValid()
        },
      ),
      otherwise: Yup.string()
    }).nullable(),
    dayToRetain: Yup.string().nullable().required(requiredMessage),
    userId: Yup.string().nullable().required(requiredMessage)
  })
}

export default makeValidationSchema
